import Head from "next/head";
import Image from "next/image";
import styles from "@/styles/Home.module.css";
import logo from "/public/images/icon/logo.svg";

export default function Home() {
  return (
    <>
      <Head>
        <title>Create Next App</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className={`${styles.main}`}>
        <div className={styles.center}>
          <a href="https://www.wukongsch.com/" rel="noopener noreferrer">
            <Image
              className={styles.logo}
              src={logo}
              alt="logo"
              width={125}
              height={40}
              priority
            />
          </a>
        </div>
      </main>
    </>
  );
}
